export const environment = {
  production: true,

  pwaFirebaseConfig: '{"apiKey":"AIzaSyD3mmElMLy0whvxpIC7VhCX4NXw4lg0tcM","authDomain":"tu-dortmund-app.firebaseapp.com","databaseURL":"https://tu-dortmund-app.firebaseio.com","projectId":"tu-dortmund-app","storageBucket":"tu-dortmund-app.appspot.com","messagingSenderId":"930124740579","appId":"1:930124740579:web:7a12ded66d9bd3338d52d6"}',
  pwaFirebaseKey: "BMDXAJYLYUZvxdeatQq2QBsxgDE1ma9PyC50JAj6J8ji_1pZ1XfvuEwCAl2bVl0byT_ZLPE0Tz1yUQKMGdAmPkQ",

  apiUrl: 'https://mobil.itmc.tu-dortmund.de',
  katalogPlusUrl: 'https://katalog.ub.tu-dortmund.de/search?q=',

  tiles: {
    home: [
      { id: 'news' },
      { id: 'canteen' },
      { id: 'events' },
      { id: 'lsf' },
      { id: 'employee-search' },
      { id: 'campus-navi' },
      { id: 'study-areas'},
      { id: 'departure-monitor' },
      { id: 'hochschulsport', url: 'https://www.buchsys.ahs.tu-dortmund.de/sportarten/aktueller_zeitraum/m.html' },
      { id: 'asta', url: 'https://service.asta-dortmund.de/app' },
      { id: 'feedback' },
      { id: 'emergency' }
    ],
    personal: [
      {
        id: 'campus-id/employee',
        iconName: 'campus-id-e',
        titleKey: 'campus-id.employee.title',
        access_roles: ['employee'],
        visible_roles: ['employee'],
      },
      {
        id: 'campus-id/student',
        iconName: 'campus-id-s',
        titleKey: 'campus-id.student.title',
        access_roles: ['student'],
        visible_roles: ['student'],
      },
      { id: 'timetable', access_roles: ['student'] },
      { id: 'ticket', access_roles: ['student'] },
      { id: 'exams', access_roles: ['student'] },
      {
        id: 'ub',
        access_roles: ['student', 'employee', 'extusers@library.tu-dortmund.de'],
        subTiles: [
          {
            id: 'pass',
            access_roles: ['student', 'employee', 'extusers@library.tu-dortmund.de'],
            iconName: 'ub-pass',
            titleKey: 'ub-pass.title',
          },
          {
            id: 'account',
            access_roles: ['student', 'employee', 'extusers@library.tu-dortmund.de'],
            iconName: 'ub-account',
            titleKey: 'ub-account.title',
          },
          {
            id: 'vscout',
            access_roles: ['student', 'employee', 'extusers@library.tu-dortmund.de'],
            url: 'https://scout-efb.ub.tu-dortmund.de',
          },
        ],
      },
      { id: 'canteen-coupon', visible_roles: ['AZUBI'], access_roles: ['AZUBI']},
      { id: 'employee-profile', access_roles: ['employee'], modal: true },
      { id: 'wlan-password', access_roles: ['member'] },
      { id: 'damage-report', access_roles: ['employee'] }
    ],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';
